import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';

import { GetSingleTips } from '../../redux/actions/tipsAdmin';

const SingleTip = () => {

  const { id } = useParams();
  // const [loggedIn, isLoggedIn] = useState(true);
  // console.log("This is id from single Tip: ", id);
  const dispatch = useDispatch();
  const SingleTip = useSelector((state) => state.singleTipReducer);

  useEffect(() => {
    dispatch(GetSingleTips(id))
  }, [dispatch, id])


  return (
    <div style={{ background: 'F1F1EA' }}>
      <div className='container mt-2 ml-3'>
        {/* {
            loggedIn ? (
          <center>
            <button  className='btn btn-dark w-25 ml-5 mt-5 mb-5' > Edit Post  </button>
          </center>
            ) : <></>
          } */}
        <div dangerouslySetInnerHTML={{ __html: SingleTip?.data?.result?.description }} />
      </div>
    </div>
  )
}

export default SingleTip
