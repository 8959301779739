// In English 
// const languages = [
// 	{ code: "aa", name: "Afar", value: "Afar", label: "Afar" },
// 	{ code: "ab", name: "Abkhazian", value: "Abkhazian", label: "Abkhazian" },
// 	{ code: "ae", name: "Avestan", value: "Avestan", label: "Avestan" },
// 	{ code: "af", name: "Afrikaans", value: "Afrikaans", label: "Afrikaans" },
// 	{ code: "ak", name: "Akan", value: "Akan", label: "Akan" },
// 	{ code: "am", name: "Amharic", value: "Amharic", label: "Amharic" },
// 	{ code: "an", name: "Aragonese", value: "Aragonese", label: "Aragonese" },
// 	{ code: "ar", name: "Arabic", value: "Arabic", label: "Arabic" },
// 	{ code: "as", name: "Assamese", value: "Assamese", label: "Assamese" },
// 	{ code: "av", name: "Avaric", value: "Avaric", label: "Avaric" },
// 	{ code: "ay", name: "Aymara", value: "Aymara", label: "Aymara" },
// 	{ code: "az", name: "Azerbaijani", value: "Azerbaijani", label: "Azerbaijani" },
// 	{ code: "ba", name: "Bashkir", value: "Bashkir", label: "Bashkir" },
// 	{ code: "be", name: "Belarusian", value: "Belarusian", label: "Belarusian" },
// 	{ code: "bg", name: "Bulgarian", value: "Bulgarian", label: "Bulgarian" },
// 	{ code: "bh", name: "Bihari languages", value: "Bihari languages", label: "Bihari languages" },
// 	{ code: "bi", name: "Bislama", value: "Bislama", label: "Bislama" },
// 	{ code: "bm", name: "Bambara", value: "Bambara", label: "Bambara" },
// 	{ code: "bn", name: "Bengali", value: "Bengali", label: "Bengali" },
// 	{ code: "bo", name: "Tibetan", value: "Tibetan", label: "Tibetan" },
// 	{ code: "br", name: "Breton", value: "Breton", label: "Breton" },
// 	{ code: "bs", name: "Bosnian", value: "Bosnian", label: "Bosnian" },
// 	{ code: "ca", name: "Catalan", value: "Catalan", label: "Catalan" },
// 	{ code: "ce", name: "Chechen", value: "Chechen", label: "Chechen" },
// 	{ code: "ch", name: "Chamorro", value: "Chamorro", label: "Chamorro" },
// 	{ code: "co", name: "Corsican", value: "Corsican", label: "Corsican" },
// 	{ code: "cr", name: "Cree", value: "Cree", label: "Cree" },
// 	{ code: "cs", name: "Czech", value: "Czech", label: "Czech" },
// 	{ code: "cu", name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic", value: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic", label: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic" },
// 	{ code: "cv", name: "Chuvash", value: "Chuvash", label: "Chuvash" },
// 	{ code: "cy", name: "Welsh", value: "Welsh", label: "Welsh" },
// 	{ code: "da", name: "Danish", value: "Danish", label: "Danish" },
// 	{ code: "de", name: "German", value: "German", label: "German" },
// 	{ code: "dv", name: "Divehi; Dhivehi; Maldivian", value: "Divehi; Dhivehi; Maldivian", label: "Divehi; Dhivehi; Maldivian" },
// 	{ code: "dz", name: "Dzongkha", value: "Dzongkha", label: "Dzongkha" },
// 	{ code: "ee", name: "Ewe", value: "Ewe", label: "Ewe" },
// 	{ code: "el", name: "Greek, Modern (1453-)", value: "Greek, Modern (1453-)", label: "Greek, Modern (1453-)" },
// 	{ code: "en", name: "English", value: "English", label: "English" },
// 	{ code: "eo", name: "Esperanto", value: "Esperanto", label: "Esperanto" },
// 	{ code: "es", name: "Spanish", value: "Spanish", label: "Spanish" },
// 	{ code: "et", name: "Estonian", value: "Estonian", label: "Estonian" },
// 	{ code: "eu", name: "Basque", value: "Basque", label: "Basque" },
// 	{ code: "fa", name: "Persian", value: "Persian", label: "Persian" },
// 	{ code: "ff", name: "Fulah", value: "Fulah", label: "Fulah" },
// 	{ code: "fi", name: "Finnish", value: "Finnish", label: "Finnish" },
// 	{ code: "fj", name: "Fijian", value: "Fijian", label: "Fijian" },
// 	{ code: "fo", name: "Faroese", value: "Faroese", label: "Faroese" },
// 	{ code: "fr", name: "French", value: "French", label: "French" },
// 	{ code: "fy", name: "Western Frisian", value: "Western Frisian", label: "Western Frisian" },
// 	{ code: "ga", name: "Irish", value: "Irish", label: "Irish" },
// 	{ code: "gd", name: "Gaelic; Scomttish Gaelic", value: "Gaelic; Scomttish Gaelic", label: "Gaelic; Scomttish Gaelic" },
// 	{ code: "gl", name: "Galician", value: "Galician", label: "Galician" },
// 	{ code: "gn", name: "Guarani", value: "Guarani", label: "Guarani" },
// 	{ code: "gu", name: "Gujarati", value: "Gujarati", label: "Gujarati" },
// 	{ code: "gv", name: "Manx", value: "Manx", label: "Manx" },
// 	{ code: "ha", name: "Hausa", value: "Hausa", label: "Hausa" },
// 	{ code: "he", name: "Hebrew", value: "Hebrew", label: "Hebrew" },
// 	{ code: "hi", name: "Hindi", value: "Hindi", label: "Hindi" },
// 	{ code: "ho", name: "Hiri Motu", value: "Hiri Motu", label: "Hiri Motu" },
// 	{ code: "hr", name: "Croatian", value: "Croatian", label: "Croatian" },
// 	{ code: "ht", name: "Haitian; Haitian Creole", value: "Haitian; Haitian Creole", label: "Haitian; Haitian Creole" },
// 	{ code: "hu", name: "Hungarian", value: "Hungarian", label: "Hungarian" },
// 	{ code: "hy", name: "Armenian", value: "Armenian", label: "Armenian" },
// 	{ code: "hz", name: "Herero", value: "Herero", label: "Herero" },
// 	{ code: "ia", name: "Interlingua (International Auxiliary Language Association)", value: "Interlingua (International Auxiliary Language Association)", label: "Interlingua (International Auxiliary Language Association)" },
// 	{ code: "id", name: "Indonesian", value: "Indonesian", label: "Indonesian" },
// 	{ code: "ie", name: "Interlingue; Occidental", value: "Interlingue; Occidental", label: "Interlingue; Occidental" },
// 	{ code: "ig", name: "Igbo", value: "Igbo", label: "Igbo" },
// 	{ code: "ii", name: "Sichuan Yi; Nuosu", value: "Sichuan Yi; Nuosu", label: "Sichuan Yi; Nuosu" },
// 	{ code: "ik", name: "Inupiaq", value: "Inupiaq", label: "Inupiaq" },
// 	{ code: "io", name: "Ido", value: "Ido", label: "Ido" },
// 	{ code: "is", name: "Icelandic", value: "Icelandic", label: "Icelandic" },
// 	{ code: "it", name: "Italian", value: "Italian", label: "Italian" },
// 	{ code: "iu", name: "Inuktitut", value: "Inuktitut", label: "Inuktitut" },
// 	{ code: "ja", name: "Japanese", value: "Japanese", label: "Japanese" },
// 	{ code: "jv", name: "Javanese", value: "Javanese", label: "Javanese" },
// 	{ code: "ka", name: "Georgian", value: "Georgian", label: "Georgian" },
// 	{ code: "kg", name: "Kongo", value: "Kongo", label: "Kongo" },
// 	{ code: "ki", name: "Kikuyu; Gikuyu", value: "Kikuyu; Gikuyu", label: "Kikuyu; Gikuyu" },
// 	{ code: "kj", name: "Kuanyama; Kwanyama", value: "Kuanyama; Kwanyama", label: "Kuanyama; Kwanyama" },
// 	{ code: "kk", name: "Kazakh", value: "Kazakh", label: "Kazakh" },
// 	{ code: "kl", name: "Kalaallisut; Greenlandic", value: "Kalaallisut; Greenlandic", label: "Kalaallisut; Greenlandic" },
// 	{ code: "km", name: "Central Khmer", value: "Central Khmer", label: "Central Khmer" },
// 	{ code: "kn", name: "Kannada", value: "Kannada", label: "Kannada" },
// 	{ code: "ko", name: "Korean", value: "Korean", label: "Korean" },
// 	{ code: "kr", name: "Kanuri", value: "Kanuri", label: "Kanuri" },
// 	{ code: "ks", name: "Kashmiri", value: "Kashmiri", label: "Kashmiri" },
// 	{ code: "ku", name: "Kurdish", value: "Kurdish", label: "Kurdish" },
// 	{ code: "kv", name: "Komi", value: "Komi", label: "Komi" },
// 	{ code: "kw", name: "Cornish", value: "Cornish", label: "Cornish" },
// 	{ code: "ky", name: "Kirghiz; Kyrgyz", value: "Kirghiz; Kyrgyz", label: "Kirghiz; Kyrgyz" },
// 	{ code: "la", name: "Latin", value: "Latin", label: "Latin" },
// 	{ code: "lb", name: "Luxembourgish; Letzeburgesch", value: "Luxembourgish; Letzeburgesch", label: "Luxembourgish; Letzeburgesch" },
// 	{ code: "lg", name: "Ganda", value: "Ganda", label: "Ganda" },
// 	{ code: "li", name: "Limburgan; Limburger; Limburgish", value: "Limburgan; Limburger; Limburgish", label: "Limburgan; Limburger; Limburgish" },
// 	{ code: "ln", name: "Lingala", value: "Lingala", label: "Lingala" },
// 	{ code: "lo", name: "Lao", value: "Lao", label: "Lao" },
// 	{ code: "lt", name: "Lithuanian", value: "Lithuanian", label: "Lithuanian" },
// 	{ code: "lu", name: "Luba-Katanga", value: "Luba-Katanga", label: "Luba-Katanga" },
// 	{ code: "lv", name: "Latvian", value: "Latvian", label: "Latvian" },
// 	{ code: "mg", name: "Malagasy", value: "Malagasy", label: "Malagasy" },
// 	{ code: "mh", name: "Marshallese", value: "Marshallese", label: "Marshallese" },
// 	{ code: "mi", name: "Maori", value: "Maori", label: "Maori" },
// 	{ code: "mk", name: "Macedonian", value: "Macedonian", label: "Macedonian" },
// 	{ code: "ml", name: "Malayalam", value: "Malayalam", label: "Malayalam" },
// 	{ code: "mn", name: "Mongolian", value: "Mongolian", label: "Mongolian" },
// 	{ code: "mr", name: "Marathi", value: "Marathi", label: "Marathi" },
// 	{ code: "ms", name: "Malay", value: "Malay", label: "Malay" },
// 	{ code: "mt", name: "Maltese", value: "Maltese", label: "Maltese" },
// 	{ code: "my", name: "Burmese", value: "Burmese", label: "Burmese" },
// 	{ code: "na", name: "Nauru", value: "Nauru", label: "Nauru" },
// 	{ code: "nb", name: "Bokmål, Norwegian; Norwegian Bokmål", value: "Bokmål, Norwegian; Norwegian Bokmål", label: "Bokmål, Norwegian; Norwegian Bokmål" },
// 	{ code: "nd", name: "Ndebele, North; North Ndebele", value: "Ndebele, North; North Ndebele", label: "Ndebele, North; North Ndebele" },
// 	{ code: "ne", name: "Nepali", value: "Nepali", label: "Nepali" },
// 	{ code: "ng", name: "Ndonga", value: "Ndonga", label: "Ndonga" },
// 	{ code: "nl", name: "Dutch; Flemish", value: "Dutch; Flemish", label: "Dutch; Flemish" },
// 	{ code: "nn", name: "Norwegian Nynorsk; Nynorsk, Norwegian", value: "Norwegian Nynorsk; Nynorsk, Norwegian", label: "Norwegian Nynorsk; Nynorsk, Norwegian" },
// 	{ code: "no", name: "Norwegian", value: "Norwegian", label: "Norwegian" },
// 	{ code: "nr", name: "Ndebele, South; South Ndebele", value: "Ndebele, South; South Ndebele", label: "Ndebele, South; South Ndebele" },
// 	{ code: "nv", name: "Navajo; Navaho", value: "Navajo; Navaho", label: "Navajo; Navaho" },
// 	{ code: "ny", name: "Chichewa; Chewa; Nyanja", value: "Chichewa; Chewa; Nyanja", label: "Chichewa; Chewa; Nyanja" },
// 	{ code: "oc", name: "Occitan (post 1500)", value: "Occitan (post 1500)", label: "Occitan (post 1500)" },
// 	{ code: "oj", name: "Ojibwa", value: "Ojibwa", label: "Ojibwa" },
// 	{ code: "om", name: "Oromo", value: "Oromo", label: "Oromo" },
// 	{ code: "or", name: "Oriya", value: "Oriya", label: "Oriya" },
// 	{ code: "os", name: "Ossetian; Ossetic", value: "Ossetian; Ossetic", label: "Ossetian; Ossetic" },
// 	{ code: "pa", name: "Panjabi; Punjabi", value: "Panjabi; Punjabi", label: "Panjabi; Punjabi" },
// 	{ code: "pi", name: "Pali", value: "Pali", label: "Pali" },
// 	{ code: "pl", name: "Polish", value: "Polish", label: "Polish" },
// 	{ code: "ps", name: "Pushto; Pashto", value: "Pushto; Pashto", label: "Pushto; Pashto" },
// 	{ code: "pt", name: "Portuguese", value: "Portuguese", label: "Portuguese" },
// 	{ code: "qu", name: "Quechua", value: "Quechua", label: "Quechua" },
// 	{ code: "rm", name: "Romansh", value: "Romansh", label: "Romansh" },
// 	{ code: "rn", name: "Rundi", value: "Rundi", label: "Rundi" },
// 	{ code: "ro", name: "Romanian; Moldavian; Moldovan", value: "Romanian; Moldavian; Moldovan", label: "Romanian; Moldavian; Moldovan" },
// 	{ code: "ru", name: "Russian", value: "Russian", label: "Russian" },
// 	{ code: "rw", name: "Kinyarwanda", value: "Kinyarwanda", label: "Kinyarwanda" },
// 	{ code: "sa", name: "Sanskrit", value: "Sanskrit", label: "Sanskrit" },
// 	{ code: "sc", name: "Sardinian", value: "Sardinian", label: "Sardinian" },
// 	{ code: "sd", name: "Sindhi", value: "Sindhi", label: "Sindhi" },
// 	{ code: "se", name: "Northern Sami", value: "Northern Sami", label: "Northern Sami" },
// 	{ code: "sg", name: "Sango", value: "Sango", label: "Sango" },
// 	{ code: "si", name: "Sinhala; Sinhalese", value: "Sinhala; Sinhalese", label: "Sinhala; Sinhalese" },
// 	{ code: "sk", name: "Slovak", value: "Slovak", label: "Slovak" },
// 	{ code: "sl", name: "Slovenian", value: "Slovenian", label: "Slovenian" },
// 	{ code: "sm", name: "Samoan", value: "Samoan", label: "Samoan" },
// 	{ code: "sn", name: "Shona", value: "Shona", label: "Shona" },
// 	{ code: "so", name: "Somali", value: "Somali", label: "Somali" },
// 	{ code: "sq", name: "Albanian", value: "Albanian", label: "Albanian" },
// 	{ code: "sr", name: "Serbian", value: "Serbian", label: "Serbian" },
// 	{ code: "ss", name: "Swati", value: "Swati", label: "Swati" },
// 	{ code: "st", name: "Sotho, Southern", value: "Sotho, Southern", label: "Sotho, Southern" },
// 	{ code: "su", name: "Sundanese", value: "Sundanese", label: "Sundanese" },
// 	{ code: "sv", name: "Swedish", value: "Swedish", label: "Swedish" },
// 	{ code: "sw", name: "Swahili", value: "Swahili", label: "Swahili" },
// 	{ code: "ta", name: "Tamil", value: "Tamil", label: "Tamil" },
// 	{ code: "te", name: "Telugu", value: "Telugu", label: "Telugu" },
// 	{ code: "tg", name: "Tajik", value: "Tajik", label: "Tajik" },
// 	{ code: "th", name: "Thai", value: "Thai", label: "Thai" },
// 	{ code: "ti", name: "Tigrinya", value: "Tigrinya", label: "Tigrinya" },
// 	{ code: "tk", name: "Turkmen", value: "Turkmen", label: "Turkmen" },
// 	{ code: "tl", name: "Tagalog", value: "Tagalog", label: "Tagalog" },
// 	{ code: "tn", name: "Tswana", value: "Tswana", label: "Tswana" },
// 	{ code: "to", name: "Tonga (Tonga Islands)", value: "Tonga (Tonga Islands)", label: "Tonga (Tonga Islands)" },
// 	{ code: "tr", name: "Turkish", value: "Turkish", label: "Turkish" },
// 	{ code: "ts", name: "Tsonga", value: "Tsonga", label: "Tsonga" },
// 	{ code: "tt", name: "Tatar", value: "Tatar", label: "Tatar" },
// 	{ code: "tw", name: "Twi", value: "Twi", label: "Twi" },
// 	{ code: "ty", name: "Tahitian", value: "Tahitian", label: "Tahitian" },
// 	{ code: "ug", name: "Uighur; Uyghur", value: "Uighur; Uyghur", label: "Uighur; Uyghur" },
// 	{ code: "uk", name: "Ukrainian", value: "Ukrainian", label: "Ukrainian" },
// 	{ code: "ur", name: "Urdu", value: "Urdu", label: "Urdu" },
// 	{ code: "uz", name: "Uzbek", value: "Uzbek", label: "Uzbek" },
// 	{ code: "ve", name: "Venda", value: "Venda", label: "Venda" },
// 	{ code: "vi", name: "Vietnamese", value: "Vietnamese", label: "Vietnamese" },
// 	{ code: "vo", name: "Volapük", value: "Volapük", label: "Volapük" },
// 	{ code: "wa", name: "Walloon", value: "Walloon", label: "Walloon" },
// 	{ code: "wo", name: "Wolof", value: "Wolof", label: "Wolof" },
// 	{ code: "xh", name: "Xhosa", value: "Xhosa", label: "Xhosa" },
// 	{ code: "yi", name: "Yiddish", value: "Yiddish", label: "Yiddish" },
// 	{ code: "yo", name: "Yoruba", value: "Yoruba", label: "Yoruba" },
// 	{ code: "za", name: "Zhuang; Chuang", value: "Zhuang; Chuang", label: "Zhuang; Chuang" },
// 	{ code: "zh", name: "Chinese", value: "Chinese", label: "Chinese" },
// 	{ code: "zu", name: "Zulu" , value: "Zulu", label: "Zulu"}

// ]

// In Spanish

const languages = [
	{ code: 'ab', name: 'Abjasio', value: 'Abjasio', label: 'Abjasio'},
	{ code: 'aa', name: 'Afar' , value: 'Afar', label: 'Afar'},
	{ code: 'af', name: 'Afrikáans' , value: 'Afrikáans', label: 'Afrikáans'},
	{ code: 'ak', name: 'Akan' , value: 'Akan', label: 'Akan'},
	{ code: 'sq', name: 'Albanés' , value: 'Albanés', label: 'Albanés'},
	{ code: 'am', name: 'Amárico' , value: 'Amárico', label: 'Amárico'},
	{ code: 'ar', name: 'Árabe' , value: 'Árabe', label: 'Árabe'},
	{ code: 'an', name: 'Aragonés' , value: 'Aragonés', label: 'Aragonés'},
	{ code: 'hy', name: 'Armenio' , value: 'Armenio', label: 'Armenio'},
	{ code: 'as', name: 'Asamés' , value: 'Asamés', label: 'Asamés'},
	{ code: 'av', name: 'Avar' , value:'Avar' , label: 'Avar'},
	{ code: 'ae', name: 'Avéstico', value: 'Avéstico', label: 'Avéstico'},
	{ code: 'ay', name: 'Aymara' , value: 'Aymara', label: 'Aymara'},
	{ code: 'az', name: 'Azerí' , value: 'Azerí', label: 'Azerí'},
	{ code: 'bm', name: 'Bambara', value: 'Bambara', label: 'Bambara'},
	{ code: 'ba', name: 'Baskir', value: 'Baskir', label: 'Baskir'},
	{ code: 'eu', name: 'Euskera', value: 'Euskera', label: 'Euskera'},
	{ code: 'be', name: 'Bielorruso', value: 'Bielorruso', label: 'Bielorruso'},
	{ code: 'bn', name: 'Bengalí', value: 'Bengalí', label: 'Bengalí'},
	{ code: 'bh', name: 'Bihari', value: 'Bihari', label: 'Bihari'},
	{ code: 'bi', name: 'Bislama', value: 'Bislama', label: 'Bislama'},
	{ code: 'bs', name: 'Bosnio', value: 'Bosnio', label: 'Bosnio'},
	{ code: 'br', name: 'Bretón' , value: 'Bretón', label: 'Bretón'},
	{ code: 'bg', name: 'Búlgaro', value: 'Búlgaro', label: 'Búlgaro'},
	{ code: 'my', name: 'Birmano', value: 'Birmano', label: 'Birmano'},
	{ code: 'ca', name: 'Catalán', value: 'Catalán', label: 'Catalán'},
	{ code: 'km', name: 'Camboyano', value: 'Camboyano', label: 'Camboyano'},
	{ code: 'ch', name: 'Chamorro', value: 'Chamorro', label: 'Chamorro'},
	{ code: 'ce', name: 'Checheno', value: 'Checheno', label: 'Checheno'},
	{ code: 'ny', name: 'Chichewa', value: 'Chichewa', label: 'Chichewa'},
	{ code: 'zh', name: 'Chino', value: 'Chino', label: 'Chino'},
	{ code: 'cu', name: 'Eslavo eclesiástico antiguo,Eslavo eclesiástico, Antiguo búlgaro eclesiástico', value: 'Eslavo eclesiástico antiguo,Eslavo eclesiástico, Antiguo búlgaro eclesiástico', label: 'Eslavo eclesiástico antiguo,Eslavo eclesiástico, Antiguo búlgaro eclesiástico'},
	{ code: 'cv', name: 'Chuvasio', value: 'Chuvasio', label: 'Chuvasio'},
	{ code: 'kw', name: 'Córnico', value: 'Córnico', label: 'Córnico'},
	{ code: 'co', name: 'Corso', value: 'Corso', label: 'Corso'},
	{ code: 'cr', name: 'Cree', value: 'Cree', label: 'Cree'},
	{ code: 'hr', name: 'Croata', value: 'Croata', label: 'Croata'},
	{ code: 'cs', name: 'Checo', value: 'Checo', label: 'Checo'},
	{ code: 'da', name: 'Danés', value: 'Danés', label: 'Danés'},
	{ code: 'dv', name: 'Maldivo', value: 'Maldivo', label: 'Maldivo'},
	{ code: 'nl', name: 'Neerlandés, Flamenco' , value: 'Neerlandés, Flamenco', label: 'Neerlandés, Flamenco'},
	{ code: 'dz', name: 'Dzongkha', value: 'Dzongkha', label: 'Dzongkha'},
	{ code: 'en', name: 'Inglés' , value: 'Inglés', label: 'Inglés'},
	{ code: 'eo', name: 'Esperanto', value: 'Esperanto', label: 'Esperanto'},
	{ code: 'et', name: 'Estonio', value: 'Estonio', label: 'Estonio'},
	{ code: 'ee', name: 'Ewé' , value: 'Ewé', label: 'Ewé'},
	{ code: 'fo', name: 'Feroés',	value: 'Feroés', label: 'Feroés'},
	{ code: 'fj', name: 'Fiyiano', value: 'Fiyiano', label: 'Fiyiano'},
	{ code: 'fi', name: 'Finés', value: 'Finés', label: 'Finés'},
	{ code: 'fr', name: 'Francés', value: 'Francés', label: 'Francés'},
	{ code: 'ff', name: 'Fula' , value: 'Fula', label: 'Fula'},
	{ code: 'gd', name: 'Gaélico escocés', value: 'Gaélico escocés', label: 'Gaélico escocés'},
	{ code: 'gl', name: 'Gallego', value: 'Gallego', label: 'Gallego'},
	{ code: 'lg', name: 'Ganda', value: 'Ganda', label: 'Ganda'},
	{ code: 'ka', name: 'Georgiano', value: 'Georgiano', label: 'Georgiano'},
	{ code: 'de', name: 'Alemán', value: 'Alemán', label: 'Alemán'},
	{ code: 'ki', name: 'Gikuyu', value: 'Gikuyu', label: 'Gikuyu'},
	{ code: 'el', name: 'Griego (moderno)', value: 'Griego (moderno)', label: 'Griego (moderno)'},
	{ code: 'kl', name: 'Groenlandés, Kalaallisut', value: 'Groenlandés, Kalaallisut', label: 'Groenlandés, Kalaallisut'},
	{ code: 'gn', name: 'Guaraní', value: 'Guaraní', label: 'Guaraní'},
	{ code: 'gu', name: 'Gujarati', value: 'Gujarati', label: 'Gujarati'},
	{ code: 'ht', name: 'Haitiano', value: 'Haitiano', label: 'Haitiano'},
	{ code: 'ha', name: 'Hausa' , value: 'Hausa', label: 'Hausa'},
	{ code: 'he', name: 'Hebreo', value: 'Hebreo', label: 'Hebreo'},
	{ code: 'hz', name: 'Herero', value: 'Herero', label: 'Herero'},
	{ code: 'hi', name: 'Hindi' , value: 'Hindi', label: 'Hindi'},
	{ code: 'ho', name: 'Hiri motu', value: 'Hiri motu', label: 'Hiri motu'},
	{ code: 'hu', name: 'Húngaro', value: 'Húngaro', label: 'Húngaro'},
	{ code: 'is', name: 'Islandés', value: 'Islandés', label: 'Islandés'},
	{ code: 'io', name: 'Ido', value: 'Ido', label: 'Ido'},
	{ code: 'ig', name: 'Igbo' , value: 'Igbo', label: 'Igbo'},
	{ code: 'id', name: 'Indonesio', value: 'Indonesio', label: 'Indonesio'},
	{ code: 'ia', name: 'Interlingua' , value: 'Interlingua', label: 'Interlingua'},
	{ code: 'ie', name: 'Occidental', value: 'Occidental', label: 'Occidental'},
	{ code: 'iu', name: 'Inuktitut', value: 'Inuktitut', label: 'Inuktitut'},
	{ code: 'ik', name: 'Inupiaq', value: 'Inupiaq', label: 'Inupiaq'},
	{ code: 'ga', name: 'Irlandés', value: 'Irlandés', label: 'Irlandés'},
	{ code: 'it', name: 'Italiano', value: 'Italiano', label: 'Italiano'},
	{ code: 'ja', name: 'Japonés', value: 'Japonés', label: 'Japonés'},
	{ code: 'jv', name: 'Javanés', value: 'Javanés', label: 'Javanés'},
	{ code: 'kn', name: 'Canarés', value: 'Canarés', label: 'Canarés'},
	{ code: 'kr', name: 'Kanuri' , value: 'Kanuri', label: 'Kanuri'},
	{ code: 'ks', name: 'Cachemiro', value: 'Cachemiro', label: 'Cachemiro'},
	{ code: 'kk', name: 'Kazajo', value: 'Kazajo', label: 'Kazajo'},
	{ code: 'rw', name: 'Kinyarwanda' ,	value: 'Kinyarwanda', label: 'Kinyarwanda'},
	{ code: 'kv', name: 'Komi' , value: 'Komi', label: 'Komi'},
	{ code: 'kg', name: 'Kikongo', value: 'Kikongo', label: 'Kikongo'},
	{ code: 'ko', name: 'Coreano', value: 'Coreano', label: 'Coreano'},
	{ code: 'kj', name: 'Kuanyama', value: 'Kuanyama', label: 'Kuanyama'},
	{ code: 'ku', name: 'Kurdo', value: 'Kurdo', label: 'Kurdo'},
	{ code: 'ky', name: 'Kirguís', value: 'Kirguís', label: 'Kirguís'},
	{ code: 'lo', name: 'Lao' , value: 'Lao', label: 'Lao'},
	{ code: 'la', name: 'Latín', value: 'Latín', label: 'Latín'},
	{ code: 'lv', name: 'Letón', value: 'Letón', label: 'Letón'},
	{ code: 'lb', name: 'Luxemburgués', value: 'Luxemburgués', label: 'Luxemburgués'},
	{ code: 'li', name: 'Limburgués', value: 'Limburgués', label: 'Limburgués'},
	{ code: 'ln', name: 'Lingala', value: 'Lingala', label: 'Lingala'},
	{ code: 'lt', name: 'Lituano', value: 'Lituano', label: 'Lituano'},
	{ code: 'lu', name: 'Luba-katanga', value: 'Luba-katanga', label: 'Luba-katanga'},
	{ code: 'mk', name: 'Macedonio',value: 'Macedonio', label: 'Macedonio'},
	{ code: 'mg', name: 'Malgache', value: 'Malgache', label: 'Malgache'},
	{ code: 'ms', name: 'Malayo', value: 'Malayo', label: 'Malayo'},
	{ code: 'ml', name: 'Malayalam', value: 'Malayalam', label: 'Malayalam'},
	{ code: 'mt', name: 'Maltés', value: 'Maltés', label: 'Maltés'},
	{ code: 'gv', name: 'Manés' , value:'Manés', label: 'Manés'},
	{ code: 'mi', name: 'Maorí', value: 'Maorí', label: 'Maorí'},
	{ code: 'mr', name: 'Maratí' , value: 'Maratí', label: 'Maratí'},
	{ code: 'mh', name: 'Marshalés' , value: 'Marshalés', label: 'Marshalés'},
	{ code: 'ro', name: 'Moldavo, Moldavo', value: 'Moldavo, Moldavo', label: 'Moldavo, Moldavo'},
	{ code: 'mn', name: 'Mongol', value: 'Mongol', label: 'Mongol'},
	{ code: 'na', name: 'Nauruano', value: 'Nauruano', label: 'Nauruano'},
	{ code: 'nv', name: 'Navajo', value: 'Navajo', label: 'Navajo'},
	{ code: 'nd', name: 'Ndebele del norte', value: 'Ndebele del norte', label: 'Ndebele del norte'},
	{ code: 'ng', name: 'Ndonga', value: 'Ndonga', label: 'Ndonga'},
	{ code: 'ne', name: 'Nepalí', value: 'Nepalí', label: 'Nepalí'},
	{ code: 'se', name: 'Sami del norte', value: 'Sami del norte', label: 'Sami del norte'},
	{ code: 'no', name: 'Noruego', value: 'Noruego', label: 'Noruego'},
	{ code: 'nb', name: 'Noruego bokmål', value: 'Noruego bokmål', label: 'Noruego bokmål'},
	{ code: 'nn', name: 'Noruego nynorsk', value: 'Noruego nynorsk', label: 'Noruego nynorsk'},
	{ code: 'ii', name: 'Nuosu', value: 'Nuosu', label: 'Nuosu'},
	{ code: 'oc', name: 'Occitano', value: 'Occitano', label: 'Occitano'},
	{ code: 'oj', name: 'Ojibwa', value: 'Ojibwa', label: 'Ojibwa'},
	{ code: 'or', name: 'Oriya', value: 'Oriya', label: 'Oriya'},
	{ code: 'om', name: 'Oromo', value: 'Oromo', label: 'Oromo'},
	{ code: 'os', name: 'Osético', value: 'Osético', label: 'Osético'},
	{ code: 'pa', name: 'Panyabí', value: 'Panyabí', label: 'Panyabí'},
	{ code: 'pi', name: 'Pali', value: 'Pali', label: 'Pali'},
	{ code: 'ps', name: 'Pastún', value: 'Pastún', label: 'Pastún'},
    { code: 'fa', name: 'Persa', value: 'Persa', label: 'Persa' },
    { code: 'pl', name: 'Polaco', value: 'Polaco', label: 'Polaco' },
    { code: 'pt', name: 'Portugués', value: 'Portugués', label: 'Portugués' },
    { code: 'qu', name: 'Quechua', value: 'Quechua', label: 'Quechua' },
    { code: 'rm', name: 'Retorrománico', value: 'Retorrománico', label: 'Retorrománico' },
    { code: 'rn', name: 'Kiroundi', value: 'Kiroundi', label: 'Kiroundi' },
    { code: 'ru', name: 'Ruso', value: 'Ruso', label: 'Ruso' },
    { code: 'sm', name: 'Samoano', value: 'Samoano', label: 'Samoano' },
    { code: 'sg', name: 'Sango', value: 'Sango', label: 'Sango' },
    { code: 'sa', name: 'Sánscrito', value: 'Sánscrito', label: 'Sánscrito' },
    { code: 'sc', name: 'Sardo', value: 'Sardo', label: 'Sardo' },
    { code: 'sr', name: 'Serbio', value: 'Serbio', label: 'Serbio' },
    { code: 'sn', name: 'Shona', value: 'Shona', label: 'Shona' },
    { code: 'sd', name: 'Sindhi', value: 'Sindhi', label: 'Sindhi' },
    { code: 'si', name: 'Cingalés', value: 'Cingalés', label: 'Cingalés' },
    { code: 'sk', name: 'Eslovaco', value: 'Eslovaco', label: 'Eslovaco' },
    { code: 'sl', name: 'Esloveno', value: 'Esloveno', label: 'Esloveno' },
    { code: 'so', name: 'Somalí', value: 'Somalí', label: 'Somalí' },
    { code: 'st', name: 'Sotho del sur', value: 'Sotho del sur', label: 'Sotho del sur' },
    { code: 'nr', name: 'Ndebele del sur', value: 'Ndebele del sur', label: 'Ndebele del sur' },
    { code: 'es', name: 'Español', value: 'Español', label: 'Español' },
    { code: 'su', name: 'Sundanés', value: 'Sundanés', label: 'Sundanés' },
    { code: 'sw', name: 'Suajili', value: 'Suajili', label: 'Suajili' },
    { code: 'ss', name: 'Suazi', value: 'Suazi', label: 'Suazi' },
    { code: 'sv', name: 'Sueco', value: 'Sueco', label: 'Sueco' },
    { code: 'tl', name: 'Tagalo', value: 'Tagalo', label: 'Tagalo' },
    { code: 'ty', name: 'Tahitiano', value: 'Tahitiano', label: 'Tahitiano' },
    { code: 'tg', name: 'Tayiko', value: 'Tayiko', label: 'Tayiko' },
    { code: 'ta', name: 'Tamil', value: 'Tamil', label: 'Tamil' },
    { code: 'tt', name: 'Tártaro', value: 'Tártaro', label: 'Tártaro' },
    { code: 'te', name: 'Telugú', value: 'Telugú', label: 'Telugú' },
    { code: 'th', name: 'Tailandés', value: 'Tailandés', label: 'Tailandés' },
    { code: 'bo', name: 'Tibetano', value: 'Tibetano', label: 'Tibetano' },
    { code: 'ti', name: 'Tigriña', value: 'Tigriña', label: 'Tigriña' },
    { code: 'to', name: 'Tongano', value: 'Tongano', label: 'Tongano' },
    { code: 'ts', name: 'Tsonga', value: 'Tsonga', label: 'Tsonga' },
    { code: 'tn', name: 'Setsuana', value: 'Setsuana', label: 'Setsuana' },
    { code: 'tr', name: 'Turco', value: 'Turco', label: 'Turco' },
    { code: 'tk', name: 'Turcomano', value: 'Turcomano', label: 'Turcomano' },
    { code: 'tw', name: 'Twi', value: 'Twi', label: 'Twi' },
    { code: 'ug', name: 'Uigur', value: 'Uigur', label: 'Uigur' },
    { code: 'uk', name: 'Ucraniano', value: 'Ucraniano', label: 'Ucraniano' },
    { code: 'ur', name: 'Urdu', value: 'Urdu', label: 'Urdu' },
    { code: 'uz', name: 'Uzbeko', value: 'Uzbeko', label: 'Uzbeko' },
    { code: 've', name: 'Venda', value: 'Venda', label: 'Venda' },
    { code: 'vi', name: 'Vietnamita', value: 'Vietnamita', label: 'Vietnamita' },
    { code: 'vo', name: 'Volapük', value: 'Volapük', label: 'Volapük' },
    { code: 'wa', name: 'Valón', value: 'Valón', label: 'Valón' },
    { code: 'cy', name: 'Galés', value: 'Galés', label: 'Galés' },
    { code: 'fy', name: 'Frisón occidental', value: 'Frisón occidental', label: 'Frisón occidental' },
    { code: 'wo', name: 'Wolof', value: 'Wolof', label: 'Wolof' },
    { code: 'xh', name: 'Xhosa', value: 'Xhosa', label: 'Xhosa' },
    { code: 'yi', name: 'Yidis', value: 'Yidis', label: 'Yidis' },
    { code: 'yo', name: 'Yoruba', value: 'Yoruba', label: 'Yoruba' },
    { code: 'za', name: 'Zhuang', value: 'Zhuang', label: 'Zhuang' },
    { code: 'zu', name: 'Zulú', value: 'Zulú', label: 'Zulú' }
];

export default languages;