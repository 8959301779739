const initialState = {
    courses: [],
}

const courseEducatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_COURSES_OF_EDUCATOR':
            return {
                ...state,
                courses: action.data
            };
        // case 'DELETE_COURSE_OF_EDUCATOR':
        //     console.log("Action Data: ", action.data);
        //     return {
        //         ...state,
        //         courses: state.courses.map(course =>
        //             course._id === action.data._id ? action.data : course
        //         )
        //     };
        default:
            return state;
    }
}

export default courseEducatorReducer;
