import React, { useEffect, useState, lazy, Suspense, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TbLanguageHiragana } from "react-icons/tb";
import { CgSandClock } from "react-icons/cg";
import { FaArrowUpRightDots } from "react-icons/fa6";
import { IoIosRocket } from "react-icons/io";
import { NavLink, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import PuffLoader from 'react-spinners/PuffLoader';
import { Box, Image } from '@chakra-ui/react';

import { GetCourseSingle } from '../../redux/actions/courseAdmin';

const SimilarCourses = lazy(() => import('./SimilarCourses'));

const CourseDescription = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  
  const getFormattedValue = (format) => {
    switch (format) {
      case 'Online':
        return 'Online';
      case 'Offline':
        return 'Presencial';
      case 'Both':
        return 'Híbrido';
      default:
        return '';
    }
  };


  useEffect(() => {
    dispatch(GetCourseSingle(id)).then((res) => {
      if (res.success) {
        setLoading(false)
      }
    });
  }, [id, dispatch]);

  const course = useSelector((state) => state.GetCourseSingleReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggleReadMore = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const readMoreStyles = useMemo(() => ({
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
  }), []);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <PuffLoader
          color="red"
          size={70}
        />
      </div>
    );
  }

  return (
    <div className='container mt-3'>
      <div className="card mb-3" style={{ boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)' }}>
        <div className="row g-0">
          <div className="col-md-4 col-sm-12">
            <img
              style={{ borderRadius: '20px' }}
              className='img-fluid p-2 d-block mx-auto w-100 h-100'
              src={course?.result?.banner_image} alt="course-image"
              loading='lazy'
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h3 className="card-title w-75">{course?.result?.title}</h3>
              <p className="card-text w-100">{course?.result?.description.substring(0, 98)}...</p>
              {course?.result?.isFree ? null :
                <p> Precio :
                  {course?.result?.price}
                </p>
              }
              <NavLink to={`${course?.result?.course_link}`} target='_blank'>
                <button className='mt-3 btn' style={{ background: '#E4B49D', fontWeight: 600 }}>
                  Saber más
                </button>
              </NavLink>
            </div>
            <div className="row mt-2 mb-3 justify-content-around p-4">
              <DetailCard
                icon={<TbLanguageHiragana size={20} />}
                label="Idioma"
                value={course?.result?.languages}
              />
              <DetailCard
                icon={<CgSandClock size={20} />}
                label="Duración"
                value={course?.result?.duration}
              />
              <DetailCard
                icon={<FaArrowUpRightDots size={20} />}
                label="Dificultad"
                value={course?.result?.difficulty}
              />
              <DetailCard
                icon={<IoIosRocket size={20} />}
                label="Formato"
                value={getFormattedValue(course?.result?.format)}
              />
              {course?.result?.isFree && (
                <div className='text-left rounded' style={{ position: 'absolute', top: 10, right: 0, background: 'green', color: 'white', padding: '10px', paddingLeft: '100px' }}>
                  Gratis
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Section title="Lo que aprenderás">
        <div style={{ opacity: '0.85' }}>
          {course?.result?.description.length > 728 ? (
            <>
              <p style={isOpen ? null : readMoreStyles} className='text-justify mt-4'>
                {course?.result?.description}...
              </p>
              <button onClick={handleToggleReadMore}>
                {isOpen ? 'Leer Menos' : 'Leer Más ...'}
              </button>
            </>
          ) : (
            <>{course?.result?.description}</>
          )}
        </div>
      </Section>
      <Section title="Sigue aprendiendo, sigue creciendo.">
        <p style={{ fontWeight: 600 }}>Descubre nuestra selección de los mejores cursos para tí.</p>
        <div style={{ opacity: '0.85' }}>
          <span>
            Disponemos de una amplia gama de cursos: desde cursos en línea hasta cursos cortos y másteres. Empieza tu búsqueda echando un vistazo a nuestros cursos más populares aquí.
          </span>
          <Suspense fallback={<div>Loading...</div>}>
            <SimilarCourses />
          </Suspense>
        </div>
      </Section>
    </div>
  );
};

const DetailCard = React.memo(({ icon, label, value }) => {
  return (
    <div className="col-md-2 text-center border border-dark rounded p-2 d-flex flex-column justify-content-center" style={{ background: '#F5F6FB', minWidth: '140px', height: 'fit-content' }}>
      <div className="col d-flex justify-content-center" style={{ opacity: 0.7 }}>{icon}</div>
      <div className="col d-flex justify-content-center" style={{ opacity: 0.7, fontSize: '15px' }}>
        <small>{label}</small>
      </div>
      <div className="col d-flex justify-content-center">
        {Array.isArray(value) ? (
          value.length === 1 ? (
            <small>{value[0]}</small>
          ) : (
            <small className='my-anchor-element'>
              {value[0]} &nbsp; +{value.length - 1} más
              <Tooltip anchorSelect=".my-anchor-element" place="top">
                {value.slice(1).join(', ')}
              </Tooltip>
            </small>
          )
        ) : (
          <small>{value}</small>
        )}
      </div>
    </div>
  );
});

const Section = React.memo(({ title, children }) => {
  return (
    <div className='border rounded mb-4' style={{ boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)' }}>
      <div className='p-4'>
        <h5 className='font-weight-bold'>{title}</h5>
        {children}
      </div>
    </div>
  );
});

export default CourseDescription;

