// reducers.js
const initialState = {
    filteredCurrentPage: 1,
    filteredCourses: [],
    totalCourse: 0,
    pageCount: 1,
    error: null
};

export const filterCoursesNormalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COURSE_NORMAL_WITH_FILTER':
            return {
                ...state,
                filteredCourses: action.data.pageinatedData,
                totalCourse: action.data.totalCourse,
                pageCount: action.data.pageCount,
                error: null
            };
        case 'SET_FILTERED_CURRENT_PAGE':
            return {
                ...state,
                filteredCurrentPage: action.data
            };
        case 'COURSE_SEARCH':
            console.log("COURSE_SEARCH_METHOD", action.data);
            return {
                ...state,
                filteredCourses: action.data?.pageinatedData,
                totalCourse: action.data?.totalCourse,
                pageCount: action.data?.pageCount,
                error: null
            };

        default:
            return state;
    }
};
