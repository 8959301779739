// visibilityActions.js

export const SHOW_COMPONENT = 'SHOW_COMPONENT';
export const HIDE_COMPONENT = 'HIDE_COMPONENT';

export const showComponent = () => ({
  type: SHOW_COMPONENT,
});

export const hideComponent = () => ({
  type: HIDE_COMPONENT,
});