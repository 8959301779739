import React, { useEffect, useState } from 'react';
import decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from './redux/actions/CurrentUser';

const ProtectedRouteEduRec = ({ component: Component }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.currentUser);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogout = () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
        dispatch(setCurrentUser(null));
    };

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('Profile'));
        if (profile && profile.token) {
            const decodedToken = decode(profile.token);
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                handleLogout();
            } else {
                setIsAuthenticated(true);
                dispatch(setCurrentUser(profile));
            }
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate]);

    if (!isAuthenticated) {
        navigate('/login')
        return ; 
    }

    return <Component />;
};

export default ProtectedRouteEduRec;
