import React, { Suspense, lazy } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

const TipsHeadSection = lazy(() => import('./TipsHeadSection'));
const TipsMidSection = lazy(() => import('./TipsMidSection'));
// const Footer = lazy(() => import('../partials/Footer'));

const Tips = () => {
  return (
    <div>
      <Suspense fallback={
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
          <PuffLoader
            size={70}
            color={"red"}
          />
        </div>
      }>
        <TipsHeadSection />
        <TipsMidSection />
        {/* <Footer /> */}
      </Suspense>
    </div>
  );
}

export default Tips;