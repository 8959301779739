import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

const NotFound = () => {
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            height="100vh" 
            bg="#FAF1EA"
            color="#E4B49D"
            textAlign="center"
            padding="20px"
        >
            <Heading fontSize="6xl" mb="4">404</Heading>
            <Heading fontSize="2xl" mb="6">Page Not Found</Heading>
            <Text fontSize="lg" mb="6">
                The page you are looking for does not exist.
            </Text>
            <a href="/" target='_blank'>
                <Button 
                    bg="#E4B49D" 
                    color="white" 
                    _hover={{ bg: "#C49785" }} 
                    size="lg"
                >
                    Go Home
                </Button>
            </a>
        </Box>
    );
};

export default NotFound;
