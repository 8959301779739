const initialState = {
    courseProvider: "",
    keyword: "",
};

const SearchfilterReducerParams = (state = initialState , action) => {
    switch (action.type) {
        case 'SET_COURSE_PROVIDER':
            return { ...state, courseProvider: action?.payload };
        case 'SET_KEYWORD':
            return { ...state, keyword: action?.payload };
        default:
            return state;
    }
};

export default SearchfilterReducerParams;