import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Flex,
    // Img,
    Button,
    Input,
    InputRightElement,
    IconButton,
    // Grid,
    // transition,
} from '@chakra-ui/react';
// import Unstop from '../../assets/img/Unstop.svg';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { IoEyeOff } from 'react-icons/io5';

import './InputLabelAnimation.css';
import Carousel from './Carousel';
import { signup } from '../../redux/actions/auth';
import { InputGroup } from 'react-bootstrap';


const Signup = () => {

    const navigate = useNavigate();
    // const messagebackend = useSelector((state) => state.authReducer.message);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [country_code, setCountryCode] = useState('');
    const [phone_no, setPhone_no] = useState('');
    const [selectType, setSelectType] = useState('candidate');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    // const [user_type, SetUserType] = useState(''); // [candidate, recruiter, organizer]

    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (messagebackend) {
    //         toast.error(messagebackend);
    //     }
    // }, [messagebackend]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (validate()) {
            const signupData = {
                firstName,
                lastName,
                email,
                password,
                confirmPassword,
                country_code,
                phone_no,
                user_type: selectType,
            };
            dispatch(signup(signupData, navigate)).then((response) => {
                if (!(response.success)) {
                    toast.error(response.message);
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setButtonLoading(false);
            });
        }
    }


    const validate = () => {
        if (!firstName || !lastName || !email || !password) {
            toast.error('Please fill all the fields');
            return false;
        }
        if (firstName.length < 2) {
            toast.error('First Name should be at least 2 characters long');
            return false;
        }
        if (password.length < 6) {
            toast.error('Password should be at least 6 characters long');
            return false;
        } if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error('Please enter a valid email');
            return false;
        }
        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
            return false;
        }
        return true;
    };

    return (
        <div
            className="signup"
            borderRadius="0px 30px 0px 30px"
            style={{
                height: 'auto',
                marginTop: '1vw',
                marginBottom: '1vw',
            }}
        >
            <ToastContainer />
            <Flex
                alignContent={'center'}
                justifyContent={'center'}
                className="signup-center-box"
            >
                <div className='curousel_box'>
                    <Carousel />
                </div>
                <Flex
                    className='box-form'
                    direction="column"
                    align="center"
                    justify="center"
                // width={{ base: '50%', md: '50%',lg:"100%" }}
                >
                    <form
                        onSubmit={handleSubmit}
                        className="form-signup"
                        style={{
                            width: '100%',
                            padding: '1rem',
                        }}
                    >
                        {/* <Img
                            className='LogoImg'
                            src={Unstop}
                            alt="unstopablePhoto"
                        /> */}
                        <div
                            style={{
                                marginLeft: '1%',
                                marginBottom: '1rem',
                            }}
                            className="text"
                        >

                            <h3 style={{ fontSize: "18px" }}>
                                Crea una cuenta como
                            </h3>

                        </div>
                        <Flex
                            className='flexButton'
                            justifyContent="center"
                            alignItems="center"
                            flexDirection={{ base: 'row', md: 'row' }} // Stack on small screens, align horizontally on medium and larger screens
                            gap={{ base: '1rem', md: '1.5rem' }} // Adjust spacing between buttons
                            mt={{ base: '1rem', md: 0 }} // Add top margin on small screens
                        >
                            <Button
                                style={{ border: selectType != "candidate" ? "2px dotted #7f7f7f" : "2px solid #cc341f", color: selectType != "candidate" ? "#000" : "#cc341f", borderRadius: "25px 25px 25px 25px", transition: "all 0.3s linear" }}
                                onClick={() => setSelectType("candidate")}
                                variant="outline"
                                borderColor="#FAEFE8"
                                color="#000"
                                size="lg"
                                fontSize="15px"
                                p={{ base: '1rem', md: '1.5rem' }} // Add padding to button}
                                w={{ base: '100%', md: 'auto' }} // Full width on small screens, auto width on medium and larger screens
                                mb={{ base: '0.5rem', md: 0 }} // Add bottom margin on small screens
                            >
                                {/* Candidate */}
                                Candidato
                            </Button>
                            <Button
                                style={{ border: selectType != "recruiter" ? "2px dotted #7f7f7f" : "2px solid #cc341f", color: selectType != "recruiter" ? "#000" : "#cc341f", borderRadius: "25px 25px 25px 25px", transition: "all 0.3s linear" }}
                                onClick={() => setSelectType("recruiter")}
                                variant="outline"
                                borderColor="#FAEFE8"
                                fontSize="15px"
                                py={{ base: '1rem', md: '1.5rem' }} // Add padding to button}
                                color="#000"
                                size="lg"
                                w={{ base: '100%', md: 'auto' }}
                                mb={{ base: '0.5rem', md: 0 }}
                            >
                                {/* Recruiter */}
                                Reclutador
                            </Button>
                            <Button
                                style={{ border: selectType != "educator" ? "2px dotted #7f7f7f" : "2px solid #cc341f", color: selectType != "educator" ? "#000" : "#cc341f", borderRadius: "25px 25px 25px 25px", transition: "all 0.3s linear" }}
                                onClick={() => setSelectType("educator")}
                                variant="outline"
                                borderColor="#FAEFE8"
                                fontSize="15px"
                                p={{ base: '1rem', md: '1.5rem' }} // Add padding to button}
                                color="#000"
                                size="lg"
                                w={{ base: '100%', md: 'auto' }}
                                mb={{ base: '0.5rem', md: 0 }}
                            >
                                {/* Educator */}
                                Escuela
                            </Button>
                        </Flex>
                        {/* <br />
                        <br /> */}
                        <div className='form-fields'>
                            <Flex className='name-container'>
                                <div class="input-field">
                                    <input
                                        type="text"
                                        required
                                        spellCheck="false"
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                    />
                                    <label>
                                        {/* First Name */}
                                        Nombre
                                    </label>
                                </div>
                                <div class="input-field">
                                    <input
                                        type="text"
                                        required
                                        spellCheck="false"
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                    />
                                    <label>
                                        {/* Last Name */}
                                        Apellido
                                    </label>
                                </div>
                            </Flex>
                            <div class="input-field">
                                <input
                                    style={{ width: '100%' }}
                                    type="text"
                                    required
                                    spellCheck="false"
                                    autoComplete="off"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label> Email </label>
                            </div>
                            {/* <div class="input-field">
                                <input
                                    style={{ width: '100%' }}

                                    type="text"
                                    required
                                    spellCheck="false"
                                    autoComplete="off"
                                />
                                <label>User Name</label>
                            </div> */}
                            <div className="input-field">
                                {/* <input
                                    style={{ width: '100%' }}
                                    type="text"
                                    required
                                    spellCheck="false"
                                    autoComplete="off" 
                                />*/}
                                <PhoneInput
                                    international
                                    defaultCountry="ES"
                                    // value={phoneNumber}
                                    // onChange={setPhoneNumber}
                                    onChange={
                                        (phoneNumber) => {
                                            if (typeof phoneNumber === 'string') {
                                                const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
                                                if (parsedPhoneNumber) {
                                                    setCountryCode(parsedPhoneNumber.countryCallingCode)
                                                    setPhone_no(parsedPhoneNumber.nationalNumber)
                                                }
                                            }
                                        }
                                    }
                                    placeholder="Phone"
                                />
                                {/* <label>Phone</label> */}
                            </div>
                            <Flex style={{ width: '100%' }} className='password-container'>
                                <div className="input-field" style={{ position: 'relative', width: '48%' }}>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        required
                                        spellCheck="false"
                                        autoComplete="off"
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{ width: '100%', paddingRight: '2.5rem' }}
                                    />
                                    <label>
                                        {/* Password */}
                                        Contraseña
                                    </label>
                                    <IconButton
                                        // type="button"
                                        icon={showPassword ? <IoMdEye /> : <IoEyeOff />}
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '0.5rem',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/* {showPassword ? 'Hide' : 'Show'} */}
                                    </IconButton>
                                </div>
                                <div className="input-field" style={{ position: 'relative', width: '48%' }}>
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        required
                                        spellCheck="false"
                                        autoComplete="off"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        style={{ width: '100%', paddingRight: '2.5rem' }}
                                    />
                                    <label>
                                        {/* Confirm Password */}
                                        Confirmar Clave
                                    </label>
                                    <IconButton
                                        // type="button"
                                        icon={showConfirmPassword ? <IoMdEye /> : <IoEyeOff />}
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '0.5rem',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/* {showConfirmPassword ? 'Hide' : 'Show'} */}
                                    </IconButton>
                                </div>
                            </Flex>
                        </div>
                        <Button
                            mt={4}
                            style={{
                                backgroundColor: '#FAEFE8',
                                color: '#CC341F',
                                width: '100%',
                                // width: '48vw',
                            }}
                            type="submit"
                            size="lg"
                            disabled={buttonLoading}
                            
                        >
                            Registrate
                        </Button>
                        <br />
                        ¿Ya tienes una cuenta?
                        <NavLink
                            style={{
                                color: '#0000f2ff',
                                textDecoration: 'none',
                            }}
                            to="/login"
                        >
                            {/* Login */}
                            Iniciar sesión
                        </NavLink>
                    </form>
                </Flex>
            </Flex >
        </div >
    );
};

export default Signup;
