import { SHOW_COMPONENT , HIDE_COMPONENT } from "../../actions/paginationToggle";

const initialState = {
  isVisible: true,
};

const paginationToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_COMPONENT:
      return {...state,isVisible: true,};
    case HIDE_COMPONENT:
      return {...state,isVisible: false,};
    default:
      return state;
  }
};

export default paginationToggleReducer;