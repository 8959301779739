import React, { useEffect, useState } from 'react'
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from 'react-redux';
import {
    courseSearchAction,
    setFilteredCurrentPageAction,
} from "../../redux/actions/courseAdmin";
import {
    hideComponent,
    showComponent,
} from "../../redux/actions/paginationToggle";

const SearchBarPaginate = () => {
    const dispatch = useDispatch()
    const [searchLimit, setSearchLimit] = useState(12);
    const [curPage, setCurPage] = useState(1);
    // const [AllFilter, setAllFilter] = useState({
    //     courseProvider: "",
    //     keyword: "",
    // });
    
    const pageCount = useSelector(
        (state) => state.filterCoursesNormalReducer.pageCount
    );

    const AllFilter = useSelector(
        (state) => state?.SearchfilterReducerParams
    )

    console.log("This is all filters from search bar paginate reducer", AllFilter);

    const handleApplyFilter = async () => {
        const params = new URLSearchParams(AllFilter).toString();
        console.log(params);
        // dispatch(courseSearchAction(params, searchPagesCount, searchLimit)).then((response) => {
        dispatch(courseSearchAction(params, curPage, searchLimit)).then(
            (response) => {
                if (response.success) {
                    console.log(response.data);
                } else {
                    console.log(response.message);
                }
            }
        );
        dispatch(hideComponent());
    };

    useEffect(() => {
        dispatch(showComponent());
    }, []);

    useEffect(() => {
        dispatch(setFilteredCurrentPageAction(curPage));
        handleApplyFilter();
    }, [curPage]);

    const handlePageChange = (e) => {
        console.log("Page selected----------------", e.selected + 1, curPage);
        setCurPage(e.selected + 1); // Adjust for 0-based index
        // dispatch(setFilteredCurrentPageAction(selectedPage)).then((res) => {
        //   if (res.success) {
        //     console.log("Page Changed Successfully");
        //     // Ensure that the currentPage state is updated before filtering courses
        //     // handleFilterCourses(selectedPage);
        //     console.log("current page--------------------", currentPage);
        //     handleApplyFilter();
        //   } else {
        //     console.log("Error Changing Page");
        //   }
        // });
    };

    return (
        <div>
            <div className="mt-4 ml-5">
                <ReactPaginate
                    breakLabel="..."
                    // nextLabel="next >"
                    nextLabel="Siguiente >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={pageCount}
                    // pageCount={filteredPageCount}
                    pageCount={pageCount}
                    // previousLabel="< previous"
                    previousLabel="< Anterior"
                    renderOnZeroPageCount={null}
                    // CSS
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={curPage - 1}
                />
            </div>
        </div>
    )
}

export default SearchBarPaginate
