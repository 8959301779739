import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CiSearch } from "react-icons/ci";
import { Flex } from "@chakra-ui/react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";

import LeftSidebar from "./LeftSidebar";
import CSS from "./SearchBar.module.css";
import {
  courseSearchAction,
  setFilteredCurrentPageAction,
} from "../../redux/actions/courseAdmin";
import {
  hideComponent,
  showComponent,
} from "../../redux/actions/paginationToggle";

const SearchBar = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchLimit, setSearchLimit] = useState(12);
  const [curPage, setCurPage] = useState(1);
  // const [searchPagesCount, setSearchPagesCount] = useState(1);
  const filteredCurrentPageR = useSelector(
    (state) => state.filterCoursesNormalReducer.filteredCurrentPage
  );

  // const showhidePagination = useSelector(())

  // uncomment to make work functional

  const currentPage = useSelector(
    (state) => state.filterCoursesNormalReducer.filteredCurrentPage
  );
  const filteredPaginatedCourses = useSelector(
    (state) => state.filterCoursesNormalReducer.filteredCourses
  );
  const pageCount = useSelector(
    (state) => state.filterCoursesNormalReducer.pageCount
  );

  // const SearchBar = ({ courseCompany }) => {
  //   const [state, dispatch] = useReducer(filterReducer, initialState);

  const handleCourseProviderChange = (e) => {
    dispatch({ type: 'SET_COURSE_PROVIDER', payload: e.value });
  };

  const handleKeywordChange = (e) => {
    dispatch({ type: 'SET_KEYWORD', payload: e.target.value });
  };

  // const handlePageChange = () => {

  // }

  // useEffect(() => {
  //   dispatch(setFilteredCurrentPageAction(curPage));
  //   handleApplyFilter();
  // }, [curPage]);

  // const handlePageChange = (e) => {
  //   console.log("Page selected----------------", e.selected + 1, curPage);
  //   setCurPage(e.selected + 1); // Adjust for 0-based index
  //   // dispatch(setFilteredCurrentPageAction(selectedPage)).then((res) => {
  //   //   if (res.success) {
  //   //     console.log("Page Changed Successfully");
  //   //     // Ensure that the currentPage state is updated before filtering courses
  //   //     // handleFilterCourses(selectedPage);
  //   //     console.log("current page--------------------", currentPage);
  //   //     handleApplyFilter();
  //   //   } else {
  //   //     console.log("Error Changing Page");
  //   //   }
  //   // });
  // };

  // uncomment to make work functional

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const [courseCompany, setCourseCompany] = useState([]);
  // const [AllFilter, setAllFilter] = useState({
  //   courseProvider: "",
  //   keyword: "",
  // });
  let CourseNames = [];

  const serverURL = process.env.REACT_APP_SERVER_URL;


  const AllFilter = useSelector(
    (state) => state?.SearchfilterReducerParams
  )
  const handleApplyFilter = async () => {
    const params = new URLSearchParams(AllFilter).toString();
    console.log(params);
    // dispatch(courseSearchAction(params, searchPagesCount, searchLimit)).then((response) => {
    dispatch(courseSearchAction(params, curPage, searchLimit)).then(
      (response) => {
        if (response.success) {
          console.log(response.data);
        } else {
          console.log(response.message);
        }
      }
    );
    dispatch(hideComponent());
  };

  useEffect(() => {
    dispatch(showComponent());
  }, []);

  const getCourseNames = async () => {
    try {
      const response = await axios.get(`${serverURL}/courses/allCompanies`);
      if (response.data.success) {
        CourseNames = response?.data?.result;
        const courseNameOptions = CourseNames?.map((CourseName) => {
          return { value: CourseName, label: CourseName };
        });
        setCourseCompany(courseNameOptions);
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Course Names Fetched");
    }
  };

  useEffect(() => {
    getCourseNames();
  }, []);

  return (
    <div>
      <div
        className={`${CSS.mainContainer} d-flex justify-content-center align-items-center`}
      >
        <div className="d-flex w-100">
          <div className="col-md-4">
            <label className="mt-2 ml-2" htmlFor="courseProvider">
              {/* Course Provider */}
              Organizador del curso
            </label>
            <Select
              options={courseCompany}
              placeholder="Seleccionar.."
              // onChange={(e) =>
              //   setAllFilter({ ...AllFilter, courseProvider: e.value })
              // }
              onChange={handleCourseProviderChange}
            />
          </div>
          {/* keyword */}
          <div className="ml-3 flex-grow-1">
            <label className="mt-2" htmlFor="keyword">
              {/* KeyWord */}
              Palabras clave
            </label>
            <input
              className="form-control"
              type="text"
              // onChange={(e) =>
              //   setAllFilter({ ...AllFilter, keyword: e.target.value })
              // }
              onChange={handleKeywordChange}
            />
          </div>

          {/* Where  */}

          {/* <div className='ml-3 flex-grow-1'>
                        <label className="mt-2 ml-2" htmlFor="where">Where</label>
                        <input className='form-control' type="text" />
                    </div> */}

          {/* Search button  */}
          <Flex>
            <div className="mt-4 pt-3 flex-grow-1">
              <button
                onClick={() =>
                  curPage === 1 ? handleApplyFilter() : setCurPage(1)
                }
                className={`${CSS.btnP} mr-2 ml-2`}
              >
                <Flex align="center" justify="center">
                  <CiSearch size={25} />
                  {/* Find Course */}
                  Buscar
                </Flex>
              </button>
              {isOpen ? (
                <LeftSidebar onClose={onClose} />
              ) : (
                <button className={`${CSS.btnF} mr-2`} onClick={onOpen}>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="ml-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z"></path>
                  </svg>
                </button>
              )}
            </div>
          </Flex>
        </div>

        {/* // uncomment to make work functional */}
        {/* <div className="mt-4 ml-5">
            <ReactPaginate
              breakLabel="..."
              // nextLabel="next >"
              nextLabel="Siguiente >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={pageCount}
              // pageCount={filteredPageCount}
              pageCount={pageCount}
              // previousLabel="< previous"
              previousLabel="< Anterior"
              renderOnZeroPageCount={null}
              // CSS
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              forcePage={curPage - 1}
            />
          </div> */}
        {/* // uncomment to make work functional */}
      </div>
    </div>
  );
};

export default SearchBar;
